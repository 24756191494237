/*@import stuff here*/

#root {
  max-height: 100vh;
  overflow-y: hidden;
}

#dossiers .ant-radio-wrapper {
  /* margins break ant's tables*/
  margin-right: 0;
}

.ant-collapse-content-box {
  padding: 4px 16px 4px 40px !important /*fuckng Antd*/;
}

.ant-menu-horizontal > .ant-menu-item {
  border: none !important;
}
.ant-layout-content .ant-menu-horizontal > .ant-menu-item:hover,
.ant-layout-content .ant-menu-horizontal > .ant-menu-submenu:hover,
.ant-layout-content .ant-menu-horizontal > .ant-menu-item-active,
.ant-layout-content .ant-menu-horizontal > .ant-menu-submenu-active,
.ant-layout-content .ant-menu-horizontal > .ant-menu-item-open,
.ant-layout-content .ant-menu-horizontal > .ant-menu-submenu-open,
.ant-layout-content .ant-menu-horizontal > .ant-menu-item-selected,
.ant-layout-content .ant-menu-horizontal > .ant-menu-submenu-selected {
  color: #eef6ff;
  background-color: #228aff;
}

.ant-tabs-content {
  height: 100%;
}

.ant-modal-close-x {
  width: 36px;
  height: 36px;
  line-height: 36px;
}

.no-radius .ant-input,
.no-radius .ant-select-selection {
  border-radius: 0;
}

.ReactVirtualized__Grid__innerScrollContainer {
  overflow: visible !important;
}

.ReactVirtualized__Table__row {
  overflow-x: visible !important;
}

.react-add-to-calendar__dropdown ul {
  padding-left: 0;
}
.react-add-to-calendar__dropdown {
  z-index: 1000;
}

a.react-add-to-calendar__button {
  white-space: nowrap;
}
